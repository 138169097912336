<template>
	<div>
		<a-spin :spinning="loading">
			<div class="ft20 ftw500 cl-main">合伙设置</div>
			
			<div class="mt20">
				
				<div class="bg-w pd30">
				<a-alert message="合伙人不论哪一级的合伙人仅是规则不一样推广的提成不一样,但都必须是自己通过邀请绑定到下级的客户产生的消费,线下消费统计不到.线上的在线行为,如果是余额消费不重复统计,余额在充值的时候统计了" banner />
				
				
					<div class="ft16 ftw500 cl-black mt16">股东合伙人设置</div>
					<div class="mt20">
						<table class="wxb-table list" style="width: 400px;">
							<thead>
								<th>股金</th>
								<th>分红比例</th>
								<th>操作</th>
							</thead>
							<tbody>
								<tr v-for="(item,index) in shareholder_partner_setting.dividend_ratio_data">
									<td>
										<a-input-number v-if="index==0" :formatter="value => `${value} 元`" v-model="item.share_capital" :min="0" :precision="2"
										 placeholder="请输入" style="width: 160px;"/>
										 <a-input-number v-else :formatter="value => `${value} 元`" v-model="item.share_capital" 
										 :min="parseFloat(shareholder_partner_setting.dividend_ratio_data[index-1].share_capital)" :precision="2"
										  placeholder="请输入" style="width: 160px;"/>
									</td>
									<td>
										<a-input-number :formatter="value => `${value}%`" v-model="item.dividend_ratio" :min="0" :max="100" :precision="2"
										 placeholder="请输入" style="width: 100px;"/>
									</td>
									<td class="clickAct" @click="delDividendRatioData(index)">
										<i class="iconfont iconbtn_close"/>
									</td>
								</tr>
							</tbody>
						</table>
						<div class="mt10">
							<a-button type="primary" icon="plus" ghost @click="addDividendRatioData()">添加</a-button>
						</div>
					</div>
					
					<div class="mt20">
						<div class="flex alcenter">
							<div class="ft14 ftw500 cl-info">是否允许绑定已有用户</div>
							<div class="ml16">
								<a-radio-group v-model="shareholder_partner_setting.is_bind_shareholder">
									<a-radio :value="1">
										是
									</a-radio>
									<a-radio :value="0">
										否
									</a-radio>
								</a-radio-group>
							</div>
						</div>
					</div>
					
					<div class="mt20">
						<a-button type="primary" @click="saveAct(1)">保存设置</a-button>
					</div>
				</div>
			</div>
				
			<div class="mt20">
				<div class="bg-w pd30">
					<div class="ft16 ftw500 cl-black">员工合伙人设置</div>
					<div class="mt20">
						<table class="wxb-table list" style="width: 600px;">
							<thead>
								<th>月业绩范围</th>
								<th>提成比例</th>
								<th>操作</th>
							</thead>
							<tbody>
								<tr v-for="(item,index) in employee_partner_setting.commission_data">
									<td v-if="index==0">
										<a-input-number :formatter="value => `${value} 元`" v-model="item.start_achievement" :min="0" :precision="2"
										 placeholder="请输入" style="width: 160px;"/>
										 -
										 <a-input-number :formatter="value => `${value} 元`" v-model="item.end_achievement" 
										 :min="parseFloat(item.start_achievement ? item.start_achievement : 0)" :precision="2"
										  placeholder="请输入" style="width: 160px;"/>
									</td>
									
									<td v-else>
										<a-input-number :formatter="value => `${value} 元`" v-model="item.start_achievement" 
										:min="parseFloat(employee_partner_setting.commission_data[index-1].end_achievement)" :precision="2"
										 placeholder="请输入" style="width: 160px;"/>
										 -
										 <a-input-number :formatter="value => `${value} 元`" v-model="item.end_achievement" 
										 :min="parseFloat(item.start_achievement ? item.start_achievement : 0)" :precision="2"
										  placeholder="请输入" style="width: 160px;"/>
									</td>
									<td>
										<a-input-number :formatter="value => `${value}%`" v-model="item.commission" :min="0" :max="100" :precision="2"
										 placeholder="请输入" style="width: 100px;"/>
									</td>
									<td class="clickAct" @click="delCommissionData(index)">
										<i class="iconfont iconbtn_close"/>
									</td>
								</tr>
							</tbody>
						</table>
						<div class="mt10">
							<a-button type="primary" icon="plus" ghost @click="addCommissionData()">添加</a-button>
						</div>
					</div>
					
					<div class="mt20">
						<div class="flex alcenter">
							<div class="ft14 ftw500 cl-info">是否允许绑定已有用户</div>
							<div class="ml16">
								<a-radio-group v-model="employee_partner_setting.is_bind_employee">
									<a-radio :value="1">
										是
									</a-radio>
									<a-radio :value="0">
										否
									</a-radio>
								</a-radio-group>
							</div>
						</div>
					</div>
					
					<div class="mt20">
						<a-button type="primary" @click="saveAct(2)">保存设置</a-button>
					</div>
				</div>
			</div>
			
			<div class="mt20">
				<div class="bg-w pd30">
					<div class="ft16 ftw500 cl-black">会员合伙人设置</div>
					
					<div class="mt20">
						<div class="flex alcenter">
							<div class="ft14 ftw500 cl-info">佣金比例</div>
							<div class="ml16">
								<a-input-number :formatter="value => `${value}%`" v-model="member_partner_setting.commission" :min="0" :max="100" :precision="2"
								 placeholder="请输入" style="width: 100px;"/>
							</div>
						</div>
					</div>
					
					<div class="mt20">
						<a-button type="primary" @click="saveAct(3)">保存设置</a-button>
					</div>
				</div>
			</div>
		</a-spin>
			
	</div>
</template>

<script>
	export default{
		data(){
			return{
				loading:false,
				confirmLoading:false,
				shareholder_partner_setting:{
					dividend_ratio_data:[],
					is_bind_shareholder:0,
				},
				employee_partner_setting:{
					commission_data:[],
					is_bind_employee:0,
				},
				member_partner_setting:{
					commission:0
				}
			}
		},
		created() {
			this.loaddata();
		},
		methods:{
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showPartnerSetting').then(res=>{
					this.shareholder_partner_setting=res.shareholder_partner_setting;
					this.employee_partner_setting=res.employee_partner_setting;
					this.member_partner_setting=res.member_partner_setting;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			saveAct(type){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/savePartnerSetting',{
					dividend_ratio_data:JSON.stringify(this.shareholder_partner_setting.dividend_ratio_data),
					is_bind_shareholder:this.shareholder_partner_setting.is_bind_shareholder,
					commission_data:JSON.stringify(this.employee_partner_setting.commission_data),
					is_bind_employee:this.employee_partner_setting.is_bind_employee,
					commission:this.member_partner_setting.commission,
					type:type
				}).then(res=>{
					this.$message.success('保存成功');
					this.loading=false;
					this.loaddata();
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			
			addDividendRatioData(){
				let dividend_ratio_data=this.shareholder_partner_setting.dividend_ratio_data;
				let length = dividend_ratio_data.length;
				this.shareholder_partner_setting.dividend_ratio_data.push({
					share_capital:length==0 ? 0 : dividend_ratio_data[length-1].share_capital,
					dividend_ratio:0,
				})
			},
			delDividendRatioData(index){
				this.shareholder_partner_setting.dividend_ratio_data.splice(index,1);
			},
			
			addCommissionData(){
				let commission_data=this.employee_partner_setting.commission_data;
				let length = commission_data.length;
				this.employee_partner_setting.commission_data.push({
					start_achievement:length==0 ? 0 : commission_data[length-1].end_achievement,
					end_achievement:length==0 ? 0 : commission_data[length-1].end_achievement,
					commission:0,
				})
			},
			delCommissionData(index){
				this.employee_partner_setting.commission_data.splice(index,1);
			},
		}
	}
</script>

<style>
</style>
